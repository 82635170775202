<!--  -->
<template>
      <div class='appraisal'>

            <div class="words" v-if="true">
                  <img :src="word">
                  <div class="switchover" v-if="false">
                        <div class="flex-align-center">AI测评报告</div>
                  </div>
            </div>
            <div class="AI flex-column" v-else>
                  <div class="title flex-align-center">“游”字作品报道</div>
                  <div class="evaluate radius flex-column">
                        <div class="topic flex-align">
                             <img src="../../assets/assistant/pingjia.png" >
                             <span>综合评价</span>
                        </div>
                        <div class="substance flex-align-between">
                              <!-- <div class="grade flex-column">
                                    <div class="picture"></div>
                                    <div class="grade_estimate"></div>
                              </div>
                              <div class="grade_cutline">2</div> -->
                        </div>
                  </div>
                  <div class="entirety_appraisal radius flex-column">
                         <div class="topic flex-align">
                              <img src="../../assets/assistant/ceping.png" >
                             <span>整体测评</span>
                             <span>（点击单字即可查看单字点评）</span>
                        </div>
                        <div class="substance">2222</div>
                  </div>
                  <div class="remarkOn radius flex-column">
                        <div class="topic flex-align">
                              <img src="../../assets/assistant/remarkOn.png" >
                             <span>单字点评</span>
                        </div>
                        <div class="substance">222</div>
                  </div>
            </div>
      </div>
</template>

<script>
      export default {
            data() {
                  return {

                  }
            },
             props: ['word'],
            //方法
            methods: {
                
            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {

            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                
            }
      }
</script>
<style lang='less' scoped>
      @import './less/appraisal.less'; 
      
</style>