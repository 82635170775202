<template>
    <div class='wordsRoll flex-align-center'>
        <vue-seamless-scroll :data="Object.keys(this.wordsData).sort()" :class-option="wordsoption"
            :classOption="{'limitMoveNum':2}" class="words-warp">
            <ul class="item">
                <li class="flex-column-center" v-for="(item, index) in Object.keys(wordsData).sort()" :key="index">
                    <div class="particulars">
                        <span>{{wordsData[item][0].time}}</span>
                        <span>{{wordsData[item][0].className}}</span>
                        <span>{{wordsData[item][0].teacherName}}</span>
                        <!-- <span>上传 “{{wordsData[item][0].lessonName}}” 作品</span> -->
                    </div>
                    <div class="words_gather">
                        <div class="img_content">
                            <template v-if="showMore">
                                <img v-for="(itemSon,indexSon) in wordsData[item]" :key="indexSon" :src="itemSon.thumbWork"
                                    @click="magnify_picture(item,itemSon)">
                            </template>
                            <template v-else>
                                <img v-for="(itemSon,indexSon) in wordsData[item].slice(0,9)" :key="indexSon"
                                    :src="itemSon.thumbWork" @click="magnify_picture(item,itemSon)">
                            </template>

                            <div class="more" v-if="wordsData[item].length>9">
                                <div @click="more">
                                    <i :class="showMore?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
                                    {{showMore?'收起':'展开'}}
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </vue-seamless-scroll>
    </div>
</template>

<script>
    import vueSeamlessScroll from 'vue-seamless-scroll';
    export default {
        data() {
            return {
                showMore: false,
                wordsoption: {
                    step: 0.5,
                    openWatch: true,
                },
                list: [{
                        'type': 3
                    },
                    {
                        'type': 3
                    },
                    {
                        'type': 2
                    },
                    {
                        'type': 2
                    },
                    {
                        'type': 2
                    },
                    {
                        'type': 2
                    },
                    {
                        'type': 2
                    },
                    {
                        'type': 2
                    },
                ],
            }
        },
        props: ['wordsData'],
        computed: {

        },
        components: {
            vueSeamlessScroll,
        },
        //方法
        methods: {
            more() {
                console.log('展开收起')
                this.showMore = !this.showMore
            },
            magnify_picture(item, itemSon) {
                this.wordsoption.step = 0;
                this.$emit('fatherMethod', itemSon.work);
            },
            magnify_start() {
                this.wordsoption.step = 0.5;
            }

        },
        //生命周期 - 创建完成（访问当前this实例）
        created() {

        },
        //生命周期 - 挂载完成（访问DOM元素）
        mounted() {}
    }
</script>
<style lang='less' scoped>
    @import '../../less/Screen.less';

    .wordsRoll {
        width: 100%;
        height: 100%;

        .words-warp {
            width: 100%;
            height: 100%;
            .distance_ML(19);
            .distance_MR(19);
            // overflow: hidden;
            overflow-y: auto;
            scrollbar-width: none;
            /* firefox */
            -ms-overflow-style: none;

            /* IE 10+ */
            &::-webkit-scrollbar {
                display: none;
                /* Chrome Safari */
            }

            ul {
                li {
                    .particulars {
                        width: 100%;
                        color: #fff;
                        font-size: 12px;
                        .distance_MB(12);
                        float: left;
                        .distance_ML(10);

                        span {
                            .distance_MR(10);
                        }
                    }

                    .words_gather {
                        .VW(415);
                        overflow-y: auto;


                        .img_content {
                            overflow-y: auto;

                            img {
                                .VW(120);
                                .VH(100);
                                cursor: pointer;
                                .distance_MB(6);
                                .distance_ML(6);
                                .distance_MR(6);

                                &:hover {
                                    box-shadow: 0 0 5px #55b5f5;
                                }
                            }

                            .more {
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                color: #fff;

                                div {
                                    cursor: pointer;
                                    display: flex;
                                    align-items: center;
                                    font-size: 14px;
                                }
                            }
                        }

                    }


                }
            }
        }
    }
</style>