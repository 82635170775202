<template>
    <div class="areaData flex-align-between">
        <div class="termBox flex-align">
            <span class="selectName">学年：</span>
            <el-select v-model="termValue" placeholder="请选择" @change="termChange">
                <el-option v-for="item in termOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
        </div>

        <div class="leftSide flex-column-between">
            <div class="graph1 frame">
                <div class="basicData flex-column-between">
                    <div class="headline headline_interval flex-align-between">
                        <span class="font">基本数据</span>
                    </div>
                    <div class="substance">
                        <div class="upperPart flex-align-between">
                            <div class="rectangle R_color1 flex-align-between">
                                <div class="flex-align-between">
                                    <img src="../../assets/datascreen/school.png" />
                                    <span>学校</span>
                                </div>
                                <span>{{ options.length }}</span>
                            </div>
                            <div class="rectangle R_color2 flex-align-between">
                                <div class="flex-align-between">
                                    <img src="../../assets/datascreen/teacher.png" />
                                    <span>教师</span>
                                </div>
                                <span>{{ essential_teacherNum }}</span>
                            </div>
                        </div>
                        <div class="flex-align-between">
                            <div class="rectangle R_color3 flex-align-between">
                                <div class="flex-align-between">
                                    <img src="../../assets/datascreen/student.png" />
                                    <span>学生</span>
                                </div>
                                <span>{{ essential_studentNum }}</span>
                            </div>
                            <div class="rectangle R_color4 flex-align-between">
                                <div class="flex-align-between">
                                    <img src="../../assets/datascreen/word.png" />
                                    <span>作品</span>
                                </div>
                                <span>{{ essential_wordNum }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-footer"></div>
            </div>
            <div class="graph2 frame">
                <div class="basicData flex-column-between">
                    <div class="headline headline_interval flex-align-between">
                        <span class="font">班级分布</span>
                    </div>
                    <div class="substance" id="classDistribution"></div>
                </div>
                <div class="panel-footer"></div>
            </div>
            <div class="graph3 frame">
                <div class="basicData flex-column-between">
                    <div class="headline headline_interval flex-align-between">
                        <span class="font">各学校作品数对比</span>
                    </div>
                    <div class="substance">
                        <vue-seamless-scroll :data="listData" :class-option="optionSingleHeightTime"
                            class="seamless-warp">
                            <ul class="item">
                                <li class="flex-align" v-for="(item, index) in listData" :key="index">
                                    <div class="schoolName flex-align">
                                        <div class="icon flex-align-center">
                                            <img :src="order_Img[index]" v-if="index < 3" />
                                            <img :src="order_Img[3]" v-else />
                                            <div class="icon_i">{{ index + 1 }}</div>
                                        </div>
                                        <div class="schoolName_box">{{ item.name }}</div>
                                    </div>
                                    <div class="progress">
                                        <!-- <el-progress :text-inside="true"
                                                                  :percentage="percentage(item.count)">
                                                            </el-progress> -->
                                    </div>
                                    <div class="words">{{ item.count }}</div>
                                </li>
                            </ul>
                        </vue-seamless-scroll>
                    </div>
                </div>
                <div class="panel-footer"></div>
            </div>
        </div>
        <div class="middle flex-column-between">
            <div class="graph6 frame">
                <div class="basicData flex-column-between">
                    <div class="headline headline_interval flex-align-between">
                        <span class="font">教学情况</span>
                        <div class="sch_select flex-align">
                            <span>当前选择学校：</span>
                            <div>
                                <el-select v-model="value" placeholder="请选择" :popper-append-to-body="false"
                                    @change="execute">
                                    <el-option v-for="(item, index) in options" :key="index" :label="item.name"
                                        :value="item.name">
                                    </el-option>
                                </el-select>
                            </div>
                            <span @click="goSch">详情 >>></span>
                        </div>
                    </div>
                    <div class="substance flex-column">
                        <div class="teachingTitle flex-align-center">教学动态</div>
                        <div class="teaching">
                            <vue-seamless-scroll :data="teachingDatas" :class-option="optionSingleHeightTime"
                                class="teaching-warp">
                                <ul class="item">
                                    <li class="flex-align-between" v-for="(item, index) in teachingDatas" :key="index">
                                        <div class="wes-2">{{ item.schoolName }}</div>
                                        <div class="wes-2">{{ item.className }}</div>
                                        <div class="wes-2">{{ item.teacherName }}</div>
                                        <div class="">
                                            {{ dayjs(item.time).format("YYYY-MM-DD HH:mm") }}
                                        </div>
                                        <div class="wes-2">{{ "“" + item.qudaName + "”学习" }}</div>
                                        <div>{{ item.countJoin }}人参与</div>
                                        <div>平均正确率{{ item.avgRightRate }}%</div>
                                    </li>
                                </ul>
                            </vue-seamless-scroll>
                        </div>
                        <div class="appliedStatistics flex-column-between">
                            <div class="headline flex-align-between">
                                <div class="font flex-align-center">上课次数统计</div>
                                <div class="font flex-align-center">平台应用统计</div>
                            </div>
                            <div class="flex-align-between">
                                <div class="classNum" id="attendClass"></div>
                                <div class="employNum">
                                    <ul>
                                        <li class="flex-column-between" v-for="(item, index) in employNumData"
                                            :key="index">
                                            <div class="employName flex-align" :style="{ color: item.color }">
                                                {{ item.name }}
                                            </div>
                                            <div class="employ_progress" :style="{ 'border-color': item.color }">
                                                <el-progress :text-inside="true" :percentage="item.num"
                                                    :color="item.color"></el-progress>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-footer"></div>
            </div>
            <div class="graph7 frame">
                <div class="basicData flex-column-between">
                    <div class="headline headline_interval flex-align-between">
                        <span class="font">汉字书写能力及素养水平</span>
                    </div>
                    <div class="substance flex-align-between">
                        <div class="calligraphyLevel" id="calligraphyLevel"></div>
                        <div class="attainment" id="attainment"></div>
                    </div>
                </div>
                <div class="panel-footer"></div>
            </div>
        </div>
        <div class="rightSide flex-column-between">
            <div class="graph4 frame">
                <div class="basicData flex-column-between">
                    <div class="headline headline_interval flex-align-between">
                        <span class="font">教师培训活动参与情况</span>
                        <!-- <div class="train_select">
                                          <el-select v-model="train_value" placeholder="请选择"
                                                :popper-append-to-body="false">
                                                <el-option v-for="item in train_options" :key="item.value"
                                                      :label="item.date" :value="item.value">
                                                </el-option>
                                          </el-select>
                                    </div> -->
                    </div>
                    <div class="substance">
                        <div class="participation" id="participator"></div>
                        <div class="par_statistics flex-align-around">
                            <div class="par_Title flex-column-center">
                                <span>活动任务</span>
                                <span>{{ essential_stage }}</span>
                            </div>
                            <div class="par_Title flex-column-center">
                                <span>参与人次</span>
                                <span>{{ essential_learn }}</span>
                            </div>
                            <div class="par_Title flex-column-center">
                                <span>提交作品</span>
                                <span>{{ essential_teacherNum }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel-footer"></div>
            </div>
            <div class="graph5 frame">
                <div class="basicData flex-column-between">
                    <div class="headline headline_interval flex-align-between">
                        <span class="font">作品实时动态（含师、生作品）</span>
                    </div>
                    <div class="substance">
                        <wordsRoll :wordsData="wordsDatas" ref="child" @fatherMethod="fatherMethod">
                        </wordsRoll>
                    </div>
                </div>
                <div class="panel-footer"></div>
            </div>
        </div>
        <div class="appraisalbox">
            <el-dialog :visible.sync="centerDialogVisible" :modal-append-to-body="false" :append-to-body="false"
                :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" center>
                <appraisal :word="dialog_words" ref="appraisal"></appraisal>
                <img class="close" src="../../assets/datascreen/close.png" @click="close" />
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import vueSeamlessScroll from "vue-seamless-scroll";
    import wordsRoll from "../../Common_components/wordRoll/wordsRoll";
    import appraisal from "../../Common_components/appraisals/appraisal";
    export default {
        data() {
            return {
                bindId: "",
                schOrgId: "",
                //基础数据
                essential_schoolNum: 0,
                essential_teacherNum: 0,
                essential_studentNum: 0,
                essential_wordNum: 0,
                //上课次数统计--区域
                attendClass_area: 0,
                //学校
                //上课次数统计--学校
                attendClass_sch: 0,
                //开课

                //活动任务数
                essential_stage: 0,
                //参与人次
                essential_learn: 0,
                //各学校作品数对比
                comparison_sum: 0,
                //----学生汉学书写能力及素养水平
                //学生书写水平
                write_indicator: [],
                write_Data: [],
                //学生汉字素养水平
                Hanzi_indicator: [],
                Hanzi_Data: [],
                // 放大图片
                dialog_words: [],
                //筛选后学校id
                executeId: "",
                order_Img: {
                    0: require("../../assets/datascreen/comparison1.png"),
                    1: require("../../assets/datascreen/comparison2.png"),
                    2: require("../../assets/datascreen/comparison3.png"),
                    3: require("../../assets/datascreen/comparison4.png"),
                },
                listData: [],
                teachingDatas: [],
                options: [],
                value: "",
                employSum: [],
                employNumData: [{
                        name: "互动课件",
                        num: 0,
                        color: "#21B48E",
                        sum: 0,
                    },
                    {
                        name: "同步投影",
                        num: 0,
                        color: "#DEA71C",
                        sum: 0,
                    },
                    {
                        name: "合作学习墙活动",
                        num: 0,
                        color: "#ED7D03",
                        sum: 0,
                    },
                    {
                        name: "直播教研",
                        num: 33,
                        color: "#EA6F54",
                        sum: 0,
                    },
                ],
                train_options: [{
                        value: "选项1",
                        date: "2022年",
                    },
                    {
                        value: "选项2",
                        date: "2021年",
                    },
                    {
                        value: "选项3",
                        date: "2020年",
                    },
                    {
                        value: "选项4",
                        date: "2019年",
                    },
                    {
                        value: "选项5",
                        date: "2018年",
                    },
                ],
                train_value: "",
                centerDialogVisible: false,
                wordsDatas: [],
                wordsoption: {
                    step: 0,
                    openWatch: true,
                },
                drawpie3DData: [{
                        name: "房山一小",
                        value: 70,
                        num: 0,
                        itemStyle: {
                            color: "#c045ed",
                        },
                        startRatio: 0,
                        endRatio: 0.7,
                    },
                    {
                        name: "海淀区",
                        value: 30,
                        num: 0,
                        itemStyle: {
                            color: "#6442ee",
                        },
                        startRatio: 0.7,
                        endRatio: 1,
                    },
                ],
                termOptions: [],
                termValue: ''
            };
        },
        computed: {
            optionSingleHeightTime() {
                return {
                    step: 0.4,
                    openWatch: true,
                };
            },
        },
        components: {
            vueSeamlessScroll,
            wordsRoll,
            appraisal,
        },
        //方法
        methods: {
            //班级分布
            drawpillar(name, data) {
                this.$echarts
                    .init(document.getElementById("classDistribution"))
                    .dispose();
                let that = this;
                let myChart = this.$echarts.init(
                    document.getElementById("classDistribution")
                );
                let color = {
                    0: "49,138,57",
                    1: "28,219,124",
                    2: "253,3,133",
                    3: "178,35,223",
                    4: "3,121,252",
                    5: "4,226,253",
                };
                myChart.setOption({
                    grid: {
                        top: "20px",
                        left: "30px",
                        right: "30px",
                        bottom: "30px",
                    },
                    xAxis: {
                        type: "category",
                        axisLine: {
                            lineStyle: {
                                color: "#fff",
                            },
                        },
                        // data: ['六年级', '五年级', '四年级', '三年级', '二年级', '一年级']
                        data: name,
                    },
                    yAxis: {
                        type: "value",
                        show: false,
                    },
                    series: [{
                        // data: [120, 200, 150, 80, 70, 110],
                        data: data,
                        type: "bar",
                        barWidth: 24.5,
                        label: {
                            show: true,
                            position: "top",
                            color: "#fff",
                        },
                        itemStyle: {
                            borderRadius: [15, 15, 0, 0],
                            color: function (params) {
                                return new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: `rgba(${color[params.dataIndex]},1)`,
                                    },
                                    {
                                        offset: 0.3,
                                        color: `rgba(${color[params.dataIndex]},0.5)`,
                                    },
                                    {
                                        offset: 1,
                                        color: `rgba(${color[params.dataIndex]},0.1)`,
                                    },
                                ]);
                            },
                        },
                    }, ],
                });
                window.addEventListener("resize", () => {
                    myChart.resize();
                });
            },
            //获取3d丙图的最高扇区的高度
            getHeight3D(series, height) {
                series.sort((a, b) => {
                    return b.pieData.value - a.pieData.value;
                });
                return (height * 25) / series[0].pieData.value;
            },
            // 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
            getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, h) {
                // 计算
                let midRatio = (startRatio + endRatio) / 2;
                let startRadian = startRatio * Math.PI * 2;
                let endRadian = endRatio * Math.PI * 2;
                let midRadian = midRatio * Math.PI * 2;
                // 如果只有一个扇形，则不实现选中效果。
                if (startRatio === 0 && endRatio === 1) {
                    isSelected = true;
                }
                // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
                k = typeof k !== "undefined" ? k : 1 / 3;
                // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
                let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
                let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;
                // 计算高亮效果的放大比例（未高亮，则比例为 1）
                let hoverRate = isHovered ? 1.05 : 1;
                // 返回曲面参数方程
                return {
                    u: {
                        min: -Math.PI,
                        max: Math.PI * 3,
                        step: Math.PI / 32,
                    },
                    v: {
                        min: 0,
                        max: Math.PI * 2,
                        step: Math.PI / 20,
                    },
                    x: function (u, v) {
                        if (u < startRadian) {
                            return (
                                offsetX +
                                Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
                            );
                        }
                        if (u > endRadian) {
                            return (
                                offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
                            );
                        }
                        return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
                    },
                    y: function (u, v) {
                        if (u < startRadian) {
                            return (
                                offsetY +
                                Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
                            );
                        }
                        if (u > endRadian) {
                            return (
                                offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
                            );
                        }
                        return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
                    },
                    z: function (u, v) {
                        if (u < -Math.PI * 0.5) {
                            return Math.sin(u);
                        }
                        if (u > Math.PI * 2.5) {
                            return Math.sin(u) * h * 0.1;
                        }
                        return Math.sin(v) > 0 ? 1 * h * 0.1 : -1;
                    },
                };
            },
            getPie3D(pieData, internalDiameterRatio) {
                //internalDiameterRatio:透明的空心占比
                let that = this;
                let series = [];
                let sumValue = 0;
                let startValue = 0;
                let endValue = 0;
                let k = 1;
                pieData.sort((a, b) => {
                    return b.value - a.value;
                });
                // 为每一个饼图数据，生成一个 series-surface 配置
                for (let i = 0; i < pieData.length; i++) {
                    sumValue += pieData[i].value;
                    let seriesItem = {
                        name: typeof pieData[i].name === "undefined" ?
                            `series${i}` : pieData[i].name,
                        type: "surface",
                        parametric: true,
                        wireframe: {
                            show: false,
                        },
                        pieData: pieData[i],
                        pieStatus: {
                            selected: false,
                            hovered: false,
                            k: k,
                        },
                        radius: "50%",
                        center: ["10%", "20%"],
                    };

                    if (typeof pieData[i].itemStyle != "undefined") {
                        let itemStyle = {};
                        typeof pieData[i].itemStyle.color != "undefined" ?
                            (itemStyle.color = pieData[i].itemStyle.color) :
                            null;
                        typeof pieData[i].itemStyle.opacity != "undefined" ?
                            (itemStyle.opacity = pieData[i].itemStyle.opacity) :
                            null;
                        seriesItem.itemStyle = itemStyle;
                    }
                    series.push(seriesItem);
                }

                // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
                // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。

                for (let i = 0; i < series.length; i++) {
                    endValue = startValue + series[i].pieData.value;
                    series[i].pieData.startRatio = startValue / sumValue;
                    series[i].pieData.endRatio = endValue / sumValue;
                    series[i].parametricEquation = this.getParametricEquation(
                        series[i].pieData.startRatio,
                        series[i].pieData.endRatio,
                        false,
                        false,
                        k,
                        series[i].pieData.value
                    );
                    startValue = endValue;
                }
                let boxHeight = this.getHeight3D(series, 25); //通过传参设定3d饼/环的高度，26代表26px
                // 准备待返回的配置项，把准备好的 legendData、series 传入。
                let option = {
                    // backgroundColor: '#203598',
                    labelLine: {
                        show: true,
                        lineStyle: {
                            color: "#404772",
                        },
                    },
                    label: {
                        show: true,
                        position: "outside",
                        color: "#fff",
                        rich: {
                            b: {
                                fontSize: 13,
                                lineHeight: 30,
                                fontWeight: "bold",
                                color: "#fff",
                            },
                            c: {
                                fontSize: 12,
                                color: "#fff",
                            },
                        },
                        // formatter: '{b|{b}}\n{c|{c}%}',
                        formatter: (data) => {
                            if (data.name == that.drawpie3DData[0].name) {
                                return data.name + " " + data.value + "%";
                            } else {
                                return data.name + "-总数：" + data.data.num;
                            }
                        },
                    },
                    tooltip: {
                        // backgroundColor: '#053A8D',
                        formatter: (params) => {
                            if (
                                params.seriesName !== "mouseoutSeries" &&
                                params.seriesName !== "pie2d"
                            ) {
                                let bfb = (
                                    (option.series[params.seriesIndex].pieData.endRatio -
                                        option.series[params.seriesIndex].pieData.startRatio) *
                                    100
                                ).toFixed(2);
                                return (
                                    `${params.seriesName}<br/>` +
                                    `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>` +
                                    `${bfb}%`
                                );
                            }
                        },
                    },
                    xAxis3D: {
                        min: -1,
                        max: 1,
                    },
                    yAxis3D: {
                        min: -1,
                        max: 1,
                    },
                    zAxis3D: {
                        min: -1,
                        max: 1,
                    },
                    grid3D: {
                        show: false,
                        boxHeight: boxHeight, //圆环的高度
                        left: 0,
                        top: 0, //3d饼图的位置
                        viewControl: {
                            //3d效果可以放大、旋转等，请自己去查看官方配置
                            alpha: 25, //角度
                            distance: 250, //调整视角到主体的距离，类似调整zoom
                            rotateSensitivity: 0, //设置为0无法旋转
                            zoomSensitivity: 0, //设置为0无法缩放
                            panSensitivity: 0, //设置为0无法平移
                            autoRotate: false, //自动旋转
                        },
                    },
                    series: series,
                };
                return option;
            },
            drawpie3D() {
                this.$echarts.init(document.getElementById("attendClass")).dispose();
                let that = this;
                let myChart = this.$echarts.init(document.getElementById("attendClass"));
                // console.log(' myChart.setOption',myChart.setOption)
                let option = this.getPie3D(this.drawpie3DData, 0.8);
                option.series.push({
                    name: "pie2d",
                    type: "pie",
                    labelLine: {
                        // show:false,
                        length: 10,
                        length2: 10,
                    },
                    startAngle: -25, //起始角度，支持范围[0, 360]。
                    clockwise: false, //饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
                    // radius: ['65%', '65%'],
                    center: ["50%", "48%"], //w指示线的位置
                    radius: ["50%", "50%"],
                    // center: ['80%', '60%'], //指示线的位置
                    data: this.drawpie3DData,
                    emphasis: false,
                    scale: false,
                });
                myChart.setOption(option);
                window.addEventListener("resize", () => {
                    myChart.resize();
                });
            },
            //学生书写水平发展趋势
            drawRadar1() {
                let splitColor = "#167374";
                this.$echarts.init(document.getElementById("calligraphyLevel")).dispose();
                let that = this;
                let myChart = this.$echarts.init(
                    document.getElementById("calligraphyLevel")
                );
                myChart.setOption({
                    color: ["#3D91F7", "#61BE67"],
                    tooltip: {
                        show: true, // 弹层数据去掉
                        formatter: (params) => {
                            let relVal = "";
                            for (let i = 0; i < params.data.value.length; i++) {
                                relVal +=
                                    that.write_indicator[i].name +
                                    " : " +
                                    that.write_Data[i] +
                                    "%" +
                                    "<br/>";
                            }
                            return relVal;
                        },
                    },
                    title: {
                        text: "学生书写水平",
                        x: "center",
                        y: "top",
                        textAlign: "left",
                        textStyle: {
                            color: "#fff",
                            fontSize: 12,
                        },
                    },
                    radar: {
                        center: ["50%", "54%"], // 外圆的位置
                        radius: "60%",
                        axisName: {
                            color: "#fff",
                            fontSize: 12,
                            fontWeight: 400,
                            fontFamily: "PingFangSC-Regular,PingFang SC",
                            fontStyle: "normal",
                        },
                        indicator: this.write_indicator,
                        splitArea: {
                            // 坐标轴在 grid 区域中的分隔区域，默认不显示。
                            show: true,
                            areaStyle: {
                                // 分隔区域的样式设置。
                                color: ["RGBA(27, 38, 80, 1)"], // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
                            },
                        },
                        axisLine: {
                            // 指向外圈文本的分隔线样式
                            lineStyle: {
                                color: splitColor,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                type: "solid",
                                color: splitColor, // 分隔线颜色
                                width: 1, // 分隔线线宽
                            },
                        },
                    },
                    series: [{
                        type: "radar",
                        symbolSize: 10,
                        symbol: "none",
                        itemStyle: {
                            borderColor: "rgba(66, 242, 185, 1)",
                            color: "#fff",
                            borderWidth: 0.2,
                        },
                        lineStyle: {
                            width: 1,
                            color: "rgba(66, 242, 185, 1)",
                        },
                        data: [{
                            // TODO:
                            // value: [80, 80, 80, 70, 60, 50],
                            value: this.write_Data,
                            name: "上学期",

                            areaStyle: {
                                color: {
                                    type: "radial",
                                    x: 0.5,
                                    y: 0.5,
                                    r: 0.5,
                                    colorStops: [{
                                            offset: 0,
                                            color: "rgba(46,203,255, 0.14)", // 0% 处的颜色
                                        },
                                        {
                                            offset: 0.15,
                                            color: "rgba(46,203,255, 0.14)", // 100% 处的颜色
                                        },
                                        {
                                            offset: 0.75,
                                            color: "rgba(46,203,255, 0.4)", // 100% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(46,203,255, 0.5)", // 100% 处的颜色
                                        },
                                    ],
                                    global: false, // 缺省为 false
                                },
                            },
                        }, ],
                    }, ],
                });
                window.addEventListener("resize", () => {
                    myChart.resize();
                });
            },
            //学生汉字素养发展趋势
            drawRadar2() {
                let splitColor = "#167374";
                this.$echarts.init(document.getElementById("attainment")).dispose();
                let that = this;
                let myChart = this.$echarts.init(document.getElementById("attainment"));
                myChart.setOption({
                    color: ["#3D91F7", "#61BE67"],
                    tooltip: {
                        show: true, // 弹层数据去掉
                        formatter: (params) => {
                            let relVal = "";
                            for (let i = 0; i < params.data.value.length; i++) {
                                relVal +=
                                    that.Hanzi_indicator[i].name +
                                    " : " +
                                    that.Hanzi_Data[i] +
                                    "%" +
                                    "<br/>";
                            }
                            return relVal;
                        },
                    },
                    title: {
                        text: "学生汉字素养",
                        x: "center",
                        y: "top",
                        textAlign: "left",
                        textStyle: {
                            color: "#fff",
                            fontSize: 12,
                        },
                    },
                    radar: {
                        center: ["50%", "54%"], // 外圆的位置
                        radius: "60%",
                        axisName: {
                            color: "#fff",
                            fontSize: 12,
                            fontWeight: 400,
                            fontFamily: "PingFangSC-Regular,PingFang SC",
                            fontStyle: "normal",
                        },
                        indicator: this.Hanzi_indicator,
                        splitArea: {
                            // 坐标轴在 grid 区域中的分隔区域，默认不显示。
                            show: true,
                            areaStyle: {
                                // 分隔区域的样式设置。
                                color: ["RGBA(27, 38, 80, 1)"], // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
                            },
                        },
                        axisLine: {
                            // 指向外圈文本的分隔线样式
                            lineStyle: {
                                color: splitColor,
                            },
                        },
                        splitLine: {
                            lineStyle: {
                                type: "solid",
                                color: splitColor, // 分隔线颜色
                                width: 1, // 分隔线线宽
                            },
                        },
                    },
                    series: [{
                        type: "radar",
                        symbolSize: 10,
                        symbol: "none",
                        itemStyle: {
                            borderColor: "rgba(66, 242, 185, 1)",
                            color: "#fff",
                            borderWidth: 0.2,
                        },
                        lineStyle: {
                            width: 1,
                            color: "rgba(66, 242, 185, 1)",
                        },
                        data: [{
                            value: this.Hanzi_Data,
                            name: "本学期",
                            itemStyle: {
                                borderColor: "rgba(245, 196, 85, 1)",
                                color: "#fff",
                                borderWidth: 0.2,
                            },
                            lineStyle: {
                                width: 1,
                                color: "rgba(245, 196, 85, 1)",
                            },
                            areaStyle: {
                                color: {
                                    type: "radial",
                                    x: 0.5,
                                    y: 0.5,
                                    r: 0.5,
                                    colorStops: [{
                                            offset: 0,
                                            color: "rgba(255, 127,0, 0.14)", // 0% 处的颜色
                                        },
                                        {
                                            offset: 0.15,
                                            color: "rgba(255, 127,0, 0.14)", // 100% 处的颜色
                                        },
                                        {
                                            offset: 0.75,
                                            color: "rgba(2255, 127,0, 0.4)", // 100% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(255, 127,0, 0.5)", // 100% 处的颜色
                                        },
                                    ],
                                    global: false, // 缺省为 false
                                },
                            },
                        }, ],
                    }, ],
                });
                window.addEventListener("resize", () => {
                    myChart.resize();
                });
            },
            //教师培训活动参与情况
            drawpie(data) {
                this.$echarts.init(document.getElementById("participator")).dispose();
                let that = this;
                let myChart = this.$echarts.init(document.getElementById("participator"));
                myChart.setOption({
                    title: {
                        text: "发帖数量",
                        textStyle: {
                            color: "#FFF",
                            fontSize: 14,
                        },
                        left: "3%",
                    },
                    color: [
                        "#01CCCC",
                        "#3E80FD",
                        "#F49961",
                        "#E25D66",
                        "#A881E9",
                        "#00FA9A",
                        "#FFFF00",
                        "#F0FFFF",
                        "#DC143C",
                        "#DDA0DD",
                    ],
                    series: [{
                        type: "pie",
                        radius: "58%",
                        label: {
                            color: "#fff",
                        },
                        labelLine: {
                            show: false,
                        },
                        data: data,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    }, ],
                });
                window.addEventListener("resize", () => {
                    myChart.resize();
                });
            },
            //调出弹窗--停止滚动
            fatherMethod(value) {
                this.centerDialogVisible = true;
                this.dialog_words = value;
            },
            goSch() {
                let that = this;
                console.log("00000000000000000000000", this.value);
                let arr = this.options.filter(item=>{
                    return item.name == this.value
                });
                console.log('arr:',arr)
                this.$router.push({
                    path: "/dataScreenMain/schoolData",
                    query: {
                        orgId: that.schOrgId,
                        bindId: that.schBindId,
                        name: this.value,
                        type: "school",
                        schoolId:arr[0].bindId
                    },
                });
            },

            //基础数据
            async basicsStatistics(id) {
                let data = {
                    chart: 3,
                    level: 4,
                    bindId: id,
                    termKey: this.termValue,
                };
                let resData = await this.$Api.DataScreen.basicsStatistics(data);
                console.log("基础数据+++++", resData.data);
                this.essential_schoolNum = resData.data.count_school.result;
                this.essential_teacherNum = resData.data.count_teacher.result;
                this.essential_studentNum = resData.data.count_student.result;
                this.essential_wordNum = resData.data.count_all_works.result;
                this.essential_stage = resData.data.count_stage.result;
                this.essential_learn = resData.data.count_learn.result;
                this.attendClass_area = resData.data.count_class_time.result;
                // this.queryOrgInfo();
            },
            //各学校作品数对比
            async queryChildrenData() {
                let data = {
                    orgId: 4,
                    chartId: 3,
                    attId: 5,
                };
                let that = this;
                let resData = await this.$Api.DataScreen.queryChildrenData(data);
                console.log("各学校作品数对比", resData, data);
                this.listData = resData.data;
                this.listData.forEach((item, index) => {
                    that.comparison_sum += item.count;
                });
            },
            percentage(value) {
                let num = value / this.comparison_sum;
                return Number(num.toFixed(2)) * 100;
            },
            //班级分布
            async queryClassCount() {
                let data = {
                    orgId: 4,
                };
                let resData = await this.$Api.DataScreen.queryClassCount(data);
                let nameArray = [];
                let numArray = [];
                for (let i = 0; i < resData.data.length; i++) {
                    nameArray.push(resData.data[i].name);
                    numArray.push(resData.data[i].count);
                }
                this.drawpillar(nameArray, numArray);
            },
            //雷达图
            async getradar1(bindId) {
                let data = {
                    chart: 2,
                    level: 4,
                    bindId: bindId,
                    termKey: this.termValue,
                };
                let resData = await this.$Api.DataScreen.basicsStatistics(data);
                console.log("雷达图1", resData.data);
                if (JSON.stringify(resData.data) == "{}") return false;
                Object.keys(resData.data)
                    .sort()
                    .forEach((item) => {
                        this.write_indicator.push({
                            name: resData.data[item].name,
                        });
                        if (resData.data[item].count == 0) {
                            this.write_Data.push(0);
                        } else {
                            this.write_Data.push(
                                Math.round(
                                    Number(resData.data[item].result) /
                                    Number(resData.data[item].count)
                                )
                            );
                        }
                    });
                let max = this.write_Data[0];
                for (let i = 1; i < this.write_Data.length; i++) {
                    max = max > this.write_Data[i] ? max : this.write_Data[i];
                }
                for (let i = 0; i < this.write_indicator.length; i++) {
                    this.write_indicator[i].max = 100;
                }
                this.drawRadar1();
            },
            async getradar2(bindId) {
                let data = {
                    chart: 1,
                    level: 4,
                    bindId: bindId,
                    termKey: this.termValue,
                };
                let resData = await this.$Api.DataScreen.basicsStatistics(data);
                console.log("雷达图2", resData.data);
                if (JSON.stringify(resData.data) == "{}") return false;
                Object.keys(resData.data)
                    .sort()
                    .forEach((item) => {
                        this.Hanzi_indicator.push({
                            name: resData.data[item].name,
                        });
                        if (resData.data[item].count == 0) {
                            this.Hanzi_Data.push(0);
                        } else {
                            this.Hanzi_Data.push(
                                Math.round(
                                    Number(resData.data[item].result) /
                                    Number(resData.data[item].count)
                                )
                            );
                        }
                    });
                let max = this.Hanzi_Data[0];
                for (let i = 1; i < this.Hanzi_Data.length; i++) {
                    max = max > this.Hanzi_Data[i] ? max : this.Hanzi_Data[i];
                }
                for (let i = 0; i < this.Hanzi_indicator.length; i++) {
                    this.Hanzi_indicator[i].max = 100;
                }
                this.drawRadar2();
            },
            //教师培训活动参与情况
            async getparticipation() {
                let data = {
                    orgId: 4,
                    chartId: 3,
                    attId: 11,
                };
                let resData = await this.$Api.DataScreen.queryChildrenData(data);
                console.log("教师培训活动参与情况", resData.data);
                let value = resData.data.slice(0, 10);
                const newParams = value.map((itme) => {
                    return {
                        name: itme["name"],
                        value: itme["count"],
                    };
                });
                this.drawpie(newParams);
            },
            //查询作品动态列表
            async queryWorkData(bindId) {
                let data = {
                    bindId: bindId,
                    level: 4,
                };
                let resData = await this.$Api.DataScreen.queryWorkData(data);
                console.log("查询作品动态列表", resData.data);
                this.wordsDatas = resData.data;
            },
            //查询平台应用数据
            async queryStatisticsApply(orgId) {
                let data = {
                    orgId: orgId,
                };
                let resData = await this.$Api.DataScreen.queryStatisticsApply(data);
                console.log("查询平台应用数据", resData.data);
                this.employNumData[0].num = resData.data.countInteractRate * 100;
                this.employNumData[1].num = resData.data.traceLengthRate * 100;
                this.employNumData[2].num = resData.data.workLengthRate * 100;
                this.employNumData[3].num = resData.data.countLiveRate * 100;
                // console.log('employNumData', this.employNumData);
                //学校
                this.drawpie3DData[0].name = this.value;
                this.drawpie3DData[0].value =
                    resData.data.learnLengthRate.toFixed(2) * 100;
                this.drawpie3DData[0].num = resData.data.learnLength;
                //区域
                this.drawpie3DData[1].name = resData.data.parentOrgName;
                this.drawpie3DData[1].value =
                    (1 - resData.data.learnLengthRate.toFixed(2)) * 100;
                this.drawpie3DData[1].num = resData.data.parentLearnLength;
                this.drawpie3D();
            },

            //教学情况
            //学校列表
            async queryOrgInfo() {
                let data = {
                    orgId: 4,
                    termKey: this.termValue,
                };
                let resData = await this.$Api.DataScreen.queryOrgInfo(data);
                //区
                this.bindId = resData.data.bindId;
                console.log("resData----", resData.data);
                //基础数据
                this.basicsStatistics(resData.data.bindId);
                //区下学校列表
                this.options = resData.data.sonOrgList;
                this.value = resData.data.sonOrgList[0].name;
                this.schOrgId = resData.data.sonOrgList[0].orgId;
                this.schBindId = resData.data.sonOrgList[0].bindId;
                this.queryTeachDataByOrgId(resData.data.sonOrgList[0].orgId);
                // this.executeId = resData.data.sonOrgList[0].bindId;
                // console.log('this.executeId', this.executeId)
                // this.statistics(this.executeId);
                //查询平台应用数据
                this.queryStatisticsApply(resData.data.sonOrgList[0].orgId);
                //汉字书写能力及素养水平
                this.getradar1(resData.data.bindId);
                this.getradar2(resData.data.bindId);
                //查询作品动态列表
                this.queryWorkData(resData.data.bindId);
            },
            //教学动态列表
            async queryTeachDataByOrgId(orgId) {
                let data = {
                    orgId: orgId,
                };
                let resData = await this.$Api.DataScreen.queryTeachDataByOrgId(data);
                console.log("教学动态列表", resData.data);
                this.teachingDatas = resData.data;
            },
            //选择当前学校--教学动态-上课次数统计-平台应用统计
            execute(val) {
                console.log('当前选择:',val);
                let that = this;
                console.log("that.value", that.value);
                console.log("this.options", this.options);
                this.options.forEach((item) => {
                    if (item.name == that.value) {
                        this.schOrgId = item.orgId;
                        this.schBindId = item.bindId;
                    }
                });
                console.log("this.schOrgId", this.schOrgId);
                this.queryTeachDataByOrgId(this.schOrgId);
                this.queryStatisticsApply(this.schOrgId);
                // this.statistics(this.executeId)
            },
            //上课统计数据 -- 学校
            async statistics(id) {
                let data = {
                    chart: 3,
                    level: 5,
                    bindId: id,
                    termKey: this.termValue,
                };
                let that = this;
                let resData = await this.$Api.DataScreen.basicsStatistics(data);
                console.log("上课次数统计", resData.data);
                this.attendClass_sch = resData.data.count_class_time.result;
            },
            close() {
                this.centerDialogVisible = false;
                this.$refs.child.magnify_start();
            },
            //获取学年学期列表 this.$Api.DataScreen
            getTermList() {
                this.$Api.DataScreen.queryTermList()
                    .then(res => {
                        console.log('学年学期列表:', res);
                        let terms = {
                            1: '上学期',
                            2: '下学期'
                        }
                        res.data.map(item => {
                            let term = {
                                value: item.key,
                                label: `${item.year}-${item.year+1}${terms[item.term]}`
                            }
                            this.termOptions.push(term)
                        })
                        this.termValue = this.termOptions[0].value;
                        this.queryOrgInfo()
                    })
                    .catch(err => {
                        console.log('err:', err)
                    })
            },
            // 选择学年
            termChange(value) {
                console.log(value);
                this.termValue = value;
            }
        },
        //生命周期 - 创建完成（访问当前this实例）
        created() {},
        //生命周期 - 挂载完成（访问DOM元素）
        mounted() {
            this.getTermList();
            // this.queryOrgInfo();
            // 班级分布
            this.queryClassCount();
            //各学校作品数对比
            this.queryChildrenData();
            //教师培训活动参与情况
            this.getparticipation();
        },
    };
</script>
<style lang="less" scoped>
    @import "./less/areaData.less";
</style>